window.addEventListener("turbolinks:load", () => {

	// Set the "other" option when Other input is focused
	let otherTextInput = document.querySelectorAll(".text-other");

	otherTextInput.forEach((input) => {
		input.addEventListener("focus", (event) => {
			input.closest("li").querySelector(".input-other").checked = true;
  	});
	})

  // Enable "other" input when Other is selected
  let optionForOtherChoice = document.querySelectorAll("input.input-other");
  if (optionForOtherChoice.length > 0) {
  	optionForOtherChoice.forEach((checkBox) => {
  		let otherInput = checkBox.closest("li").querySelector(".text-other");
  		checkBox.addEventListener("change", (event) => {
	  		if (checkBox.checked == true) {
					otherInput.disabled = false
					otherInput.focus()
	  		} else { // TODO does not work in the other way for radios
					otherInput.disabled = true
					otherInput.value = ""
	  		}
	  	})
		})
  }

  let scaleBtns = document.querySelectorAll("label.btn.scale-btn");
  if (scaleBtns) {
  	scaleBtns.forEach((scaleBtn) => {
  		scaleBtn.addEventListener("mouseover", (event) => {
	  		// Collect all scale btns in the same bar - before & after
	  		let neighbourBtns = scaleBtn.closest(".scale-bar").querySelectorAll("label.btn.scale-btn");
	  		neighbourBtns.forEach((btn) => {
	  			if (parseInt(btn.dataset.scaleno) < parseInt(scaleBtn.dataset.scaleno)+1) {
	  				btn.style.backgroundColor = "#EAEBED";
	  			}
	  		})
  		})
  		scaleBtn.addEventListener("mouseout", (event) => {
	  		// Collect all scale btns in the same bar - before & after
	  		let neighbourBtns = scaleBtn.closest(".scale-bar").querySelectorAll("label.btn.scale-btn");
	  		neighbourBtns.forEach((btn) => {
  				btn.style.backgroundColor = "#F4F5F6";
	  		})
  		})
  	});
  } 
	
});





