import Rails from '@rails/ujs';
import * as bootstrap from "bootstrap"
import Dropzone from "dropzone"
import "dropzone/dist/dropzone.css";
import Picker from 'vanilla-picker';


export function initStyleForm() {
	let layoutForm = document.querySelector("#layout-form")
	let styleForm = document.querySelector("#style-form")
	let styleFormRefresh = document.querySelector("#style-form-refresh")
	let logoForm = document.querySelector("#logo-form")

	if (logoForm) {
		let logoInput = logoForm.querySelector("#form_logo")
		if (logoInput) {
			logoInput.addEventListener("change", (event) => {
				Rails.fire(logoForm, "submit")
			})
		}
		let removeLogoBtn = logoForm.querySelector("#remove-logo")
		if (removeLogoBtn) {
			removeLogoBtn.addEventListener("click", (event) => {
				removeLogoBtn.closest(".widget-body").querySelector("#remove-logo-input").value = "true"
				removeLogoBtn.closest(".widget-body").querySelector(".btn-add-logo").innerHTML = "<i class='bi bi-image'> No logo selected.";
				Rails.fire(logoForm, "submit")
				removeLogoBtn.closest(".widget-body").querySelector("#remove-logo-input").value = "false"
			})
		}
	}

	if (layoutForm) {
		let inputsWithRefresh = layoutForm.querySelectorAll(".submit-on-change")
		if (inputsWithRefresh.length > 0) {
			inputsWithRefresh.forEach((input) => {
				input.addEventListener("change", (event) => {
					Rails.fire(layoutForm, "submit")
				})
			})
		}
	}

	
	if (styleForm) {
		let inputsWithRefresh = styleFormRefresh.querySelectorAll(".submit-on-change")
		if (inputsWithRefresh.length > 0) {
			inputsWithRefresh.forEach((input) => {
				input.addEventListener("change", (event) => {
					Rails.fire(styleFormRefresh, "submit")
					input.closest(".input-with-refresh").querySelector(".hidden-message").classList.remove("hidden")
					setTimeout(() => {
					  input.closest(".input-with-refresh").querySelector(".hidden-message").classList.add("hidden")
					}, "3000")
				})
			})
		}


		let colorPickers = styleForm.querySelectorAll(".color-picker")
		if (colorPickers.length > 0) {

			colorPickers.forEach((picker) => {
				let hiddenInputColor = picker.closest(".d-flex").querySelector("input")
				new Picker({
					parent: picker,
					popup: "left",
					color: hiddenInputColor.value,
					onChange: function(color) {
						picker.style.color = color.hex
						hiddenInputColor.value = color.hex;

					},
					onDone: function(color) {
						hiddenInputColor.value = color.hex;
						Rails.fire(styleForm, "submit");
						applyStyles(picker.dataset.target, picker.dataset.style, color.hex)
					}
				})
			})
		}

		let inputsForSubmission = styleForm.querySelectorAll(".submit-on-change")
		if (inputsForSubmission.length > 0) {
			inputsForSubmission.forEach((input) => {
				if (input.type == "text") {
					input.addEventListener("focusout", (event) => {
						if (input.dataset.target) {
							let els = document.querySelectorAll(input.dataset.target);
							els.forEach((el) => {
								console.log(el)
								el.value = input.value;
							})
						}
						Rails.fire(styleForm, "submit")
					})
				} else  {
					input.addEventListener("change", (event) => {
						if (input.dataset.style) {
							// Card opacity - set via range input - is irregular style
							if (input.type == "range") {
								let els = document.querySelectorAll(input.dataset.target);
								els.forEach((el) => {
									el.style.[input.dataset.style] = "rgba(255, 255, 255,"+ input.value + ")";
								})
							} else {
								applyStyles(input.dataset.target, input.dataset.style, input.value)
							}
						} else if (input.dataset.classType) {
							let targets = input.dataset.target.split(",")
							targets.forEach((target) => {
								changeClass(target, input.dataset.classType, input.value)								
							})
						}

						Rails.fire(styleForm, "submit")
					})
				}
			})
		}

		let backgroundImgBtn = styleForm.querySelector(".btn-add-img")
		if (backgroundImgBtn) {
			backgroundImgBtn.addEventListener("click", (event) => {
				updateImgModalIds(backgroundImgBtn, styleForm)
			})
		}
	}	
}

function applyStyles(target, attribute, style) {
	let elements = document.querySelectorAll(target);
	let attrs = null
	if (attribute.includes(",")) {
		attrs = attribute.split(",")
	} else {
		attrs = [attribute]
	}
	elements.forEach((el) => {
		attrs.forEach((attr) => {
			el.style.[attr] = style;
		})
	})
}

function changeClass(target, classType, newClass) {
	let classTypeOptions = {
		"shadow": ["shadow-none", "shadow-sm", "shadow", "shadow-lg", "shadow-re"],
		"buttonstyle": ["btn-solid-submit", "btn-outline-submit"],
		"rounding": ["rounded-0", "rounded-3", "rounded-pill"],
		"strokewidth": ["border-0", "border-1", "border-5"],
		"animate": ["animate__fadeInUp", "animate__backInDown", "animate__bounceInDown", "animate__flipInX", "animate__zoomIn", "animate__slideInLeft"]
	}

	let elements = document.querySelectorAll(target);
	elements.forEach((el) => {
		el.classList.remove(...classTypeOptions[classType]);
		el.classList.add(newClass)
	})
}


export function updateImgModalIds(btn, formEl) {
	let questionId = formEl.dataset.questionId;
	let formId = formEl.dataset.formId
	let imgModal = document.querySelector(".image-modal")
	let form = imgModal.querySelector("form")
	event.preventDefault();

	imgModal.dataset.formId = formId

	if (questionId) {
		imgModal.dataset.questionId = questionId
		form.setAttribute("action", ["questions/", questionId, "/upload_image"].join(""));
		form.querySelector("input[name='record_type']").value = "question"
		let recordId = btn.closest(".image-block").dataset.id
		form.querySelector("input[name='record_id']").value = recordId;
	} else {
		imgModal.dataset.questionId = ""
		form.setAttribute("action", ["/forms/", formId, "/upload_image"].join(""));
	}

	let imgBsModal = bootstrap.Modal.getOrCreateInstance(imgModal)
	imgBsModal.show();


	// DROPZONE
	let defaultDropzone = null

	// In case dropzone is already init, destroy it and its listeners
	if (imgModal.querySelector(".dz-clickable")) {
		defaultDropzone = imgModal.querySelector(".dropzone").dropzone 
		defaultDropzone.destroy();
	}

	defaultDropzone = new Dropzone(".dropzone", {
		method: "put",
		acceptedFiles: "image/*",
		headers: {
	    'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
	  }
	});

	defaultDropzone.on("success", (file, response) => {
		imgBsModal.hide();
		let imageId = response["image_id"]
		btn.closest(".image-block").querySelector(".hidden-img-id").value = imageId;
		btn.closest(".image-block").querySelector(".hidden-img-url").value = "";
		btn.innerHTML = "<img height='20' src="+ file.dataURL +">  &nbsp;" + truncateString(file.name, 15) + "</img>"

		if (formId && formId != "undefined") {
			// Apply image to background
			applyStyles("body", "background-image", "url('"+file.dataURL+"')")				
		}

		defaultDropzone.destroy();
	})
}


export function initImgModal() {
	let imgModal = document.querySelector(".image-modal")
	if (!imgModal) {
		return false
	}
	let giphySearchInput = imgModal.querySelector(".img-giphy .giphy-search-input")
	let unsplashSearchInput = imgModal.querySelector(".img-unsplash .unsplash-search-input")
	let imgBsModal = bootstrap.Modal.getOrCreateInstance(imgModal)

	imgModal.querySelector(".tab-giphy").addEventListener("shown.bs.tab", (event) => {
		fetchGiphies(event.target)
	})

	imgModal.querySelector(".tab-unsplash").addEventListener("shown.bs.tab", (event) => {
		fetchSplashies(event.target)
	})

	imgModal.addEventListener("click", (event) => {
		let el = event.target
		event.preventDefault();

		let recordId = imgModal.querySelector(".img-upload input#record_id").value;
		let questionId = imgModal.dataset.questionId
		let formId = imgModal.dataset.formId
		let imgBtn = null
		if (recordId == "other") {
			document.querySelectorAll("#option-"+recordId).forEach((i) => {
				if (i.dataset.questionId == questionId) {
					imgBtn = i.querySelector(".btn-add-question-img");
				}
			});
		} else if (questionId !== "") {
			 	if (recordId !== questionId) {
			 		imgBtn = document.querySelector("#option-"+recordId+" .btn-add-question-img");
			 	} else {
					imgBtn = document.querySelector(".btn-add-question-img")
			 	}
		} else {
			imgBtn = document.querySelector(".btn-add-img")
		}

		// Set Giphy OR Unsplash URL as input value & save
		if (el.classList.contains("giphy") || el.classList.contains("unsplash")) {
			imgBtn.closest(".image-block").querySelector(".hidden-img-url").value = el.dataset.imageUrl;
			imgBtn.closest(".image-block").querySelector(".hidden-img-id").value = "";
			imgBsModal.hide();			

			let provider = el.classList[0]
			imgBtn.innerHTML = "<img height='20' src="+ el.dataset.imageUrl +"> &nbsp;"+ provider
			Rails.fire(imgBtn.closest("form"), "submit");

			
			if (formId != "undefined") {
				// Apply image to background
				applyStyles("body", "background-image", "url('"+el.dataset.imageUrl+"')")				
			}

			if (provider == "unsplash") {
				downloadSplashie(el, el.dataset.downloadUrl)
			}
		}

		if (el.classList.contains("giphy-search-btn")) {
			fetchGiphies(el, giphySearchInput.value)
		}

		if (el.classList.contains("more-giphies-btn")) {
			fetchGiphies(el, giphySearchInput.value, el.dataset.page)
		}

		if (el.classList.contains("unsplash-search-btn")) {
			fetchSplashies(el, unsplashSearchInput.value)
		}

		if (el.classList.contains("more-splashies-btn")) {
			fetchSplashies(el, unsplashSearchInput.value, el.dataset.page)
		}

		if (el.classList.contains("btn-remove-img")) {
			imgBtn.closest(".image-block").querySelector(".hidden-img-id").value = ""
			imgBtn.closest(".image-block").querySelector(".hidden-img-url").value = ""
			imgBtn.innerHTML = "<i class='bi bi-image'>";

			Rails.fire(imgBtn.closest("form"), "submit");
		}
	})

	imgModal.addEventListener("focusout", (event) => {
		let el = event.target

		if (el.classList.contains("giphy-search-input")) {
			fetchGiphies(el, el.value)
		}
		if (el.classList.contains("unsplash-search-input")) {
			fetchSplashies(el, el.value)
		}
	})

	imgModal.addEventListener('keypress', (event) => {
  	let el = event.target
    if (event.key === 'Enter') {
			if (el.classList.contains("giphy-search-input")) {
				fetchGiphies(el, el.value)
			}
			if (el.classList.contains("unsplash-search-input")) {
				fetchSplashies(el, el.value)
			}
    }
  })
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}



function fetchGiphies(element, query="", offset="0", method="search") {
	if (query === "") {method = "trending"}
	console.log("fetching "+ query + "...")
	element.closest(".modal-content").querySelector(".spinner-container").classList.remove("invisible");
	fetch("https://api.giphy.com/v1/gifs/"+method+"?api_key=VrBE52TLvOJegsZqxCkkI646AVzVDWsW&limit=20&rating=g&q="+query+"&offset="+offset)
		.then(response => response.json())
		.then(data => {
			let giphies = [].slice.call(data.data);
			let moreGiphies = element.closest(".modal-body").querySelector(".more-giphies")
			element.closest(".modal-content").querySelector(".spinner-container").classList.add("invisible");

			if (giphies.length > 0) {
				// Remove the shown giphies is searching from 0
				if (offset === "0") { 
					element.closest(".modal-body").querySelector(".giphy-previews").innerHTML = ""
				}

				giphies.forEach(img => {
					element.closest(".modal-body").querySelector(".giphy-previews")
						.insertAdjacentHTML("beforeend", '<img data-image-url="'+img.images.original.url+'" class="giphy" src='+img.images.fixed_width.url+'>');
				})

				moreGiphies.classList.remove("invisible")
				moreGiphies.querySelector("button").dataset.page = (parseInt(offset) + 20).toString()

			} else {
				element.closest(".modal-body").querySelector(".giphy-previews").innerHTML = "No GIFs were found. Try a different search query."
				moreGiphies.classList.add("invisible")
			}
		})
}

function fetchSplashies(element, query="", page="1") {
	console.log("fetching "+ query + "...")
	element.closest(".modal-content").querySelector(".spinner-container").classList.remove("invisible");
	Rails.ajax({
		url: '/forms/find_unsplash_images?query='+query+'&page='+page,
		type: "GET",
		dataType: "json",
		success: function(data) {
			let splashies = data.images;
			element.closest(".modal-content").querySelector(".spinner-container").classList.add("invisible");
			let moreSplashies = element.closest(".modal-body").querySelector(".more-splashies")

			if (splashies.length > 0) {

				// Remove the shown giphies is searching from 0
				if (page === "1") { 
					element.closest(".modal-body").querySelector(".unsplash-previews").innerHTML = ""
				}

				splashies.forEach(img => {
					element.closest(".modal-body").querySelector(".unsplash-previews")
						.insertAdjacentHTML("beforeend", '<figure class="figure"><img data-image-url="'+img.original+'" data-download-url="'+img.download_link+'" data-user-name="'+img.user_name+'" class="unsplash" src='+img.thumb+'><figcaption class="figure-caption"><a href="'+img.user_link+'">'+img.user_name+'</a></figcaption></figure>');
				})

				moreSplashies.classList.remove("invisible")
				moreSplashies.querySelector("button").dataset.page = (parseInt(page) + 1).toString()

			} else {
				element.closest(".modal-body").querySelector(".unsplash-previews").innerHTML = "No photos were found. Try a different search query."
				moreSplashies.classList.add("invisible")
			}
		}
	})
}

function downloadSplashie(element, download_url) {
	Rails.ajax({
		url: '/forms/download_unsplash_image?download_url='+download_url,
		type: "GET",
		dataType: "json"
	})
}
	
function truncateString(str, n) {
  if (str.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}






