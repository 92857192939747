"use strict";
import Rails from '@rails/ujs';
import * as bootstrap from "bootstrap"
import * as editorInit from "js/editor_inits"
import * as styleInit from "js/style_inits"
import * as emojis from "js/emoji_picker"


window.addEventListener("turbolinks:load", () => {

	let toasts = document.querySelectorAll(".toast");
	toasts.forEach((toast) => {
		if (toast.dataset.notice === "true") {
			let toastNotice = new bootstrap.Toast(toast);
    	toastNotice.show()
		}
	})

	// Initialize toaster to show Success/Error messages
	document.addEventListener("ajax:success", (event) => {
		if (!event.target.classList.contains("no-toast")) {
			let toastSuccess = document.getElementById('toast-success')
	    let toast = new bootstrap.Toast(toastSuccess)
	    toastSuccess.querySelector(".toast-body").innerHTML = "<b>Saved.</b>"
	    toast.show()			
		}
		if (!event.target.classList.contains("keep-modal-open")) {
	    let openModal = document.querySelector(".modal.show");
			if (openModal && openModal.dataset.keepOpen != "true") {
				let openModalInstance = bootstrap.Modal.getInstance(openModal)
				openModalInstance.hide()
			}
		}
  });

  document.addEventListener("ajax:error", (event) => {
		let toastError = document.getElementById('toast-danger')
    let toast = new bootstrap.Toast(toastError)
    toast.show()
  });

  document.addEventListener("mouseover", (event) => {
  	if (event.target.dataset.bsToggle == "tooltip") {	
  		let tooltip = bootstrap.Tooltip.getOrCreateInstance(event.target)
  		tooltip.show()
  	}
  })

  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
	const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  
	new emojis.EmojiPicker({
		trigger: [
		    {
		        selector: '.emoji-picker-input',
		        insertInto: '.form-control'// '.selector' can be used as array
		    }
		],
		closeButton: true,
		//specialButtons: green
	})

  // Submit the form on form name change
  let formNameInput = document.querySelector("#form-name-input");
  if (formNameInput) {
  	formNameInput.addEventListener("blur", function(event) {
			let formForm = formNameInput.closest("form");
			Rails.fire(formForm, "submit");
  	})
  }


	editorInit.getOrSetFormId();
	styleInit.initImgModal();
	styleInit.initStyleForm();

	editorInit.initSortableQuestions();

	// Add eventListeners to all existing question Edit btns
	var editBtns = document.querySelectorAll(".btn-edit-question")
	editBtns.forEach((btn) => {
		let questionId = btn.closest(".question-response-card").dataset.questionId;
		editorInit.editBtnInit(btn, questionId);
	})

	// Init the form after added at the bottom
	var addQuestionBtns = document.querySelectorAll("a.new-question-btn, a.new-ending-btn")
	addQuestionBtns.forEach((btn) => {
		editorInit.addQuestionBtnInit(btn)
	})

  let publishBtn = document.querySelector("#btn-publish");
  if (publishBtn) {
  	publishBtn.addEventListener("click", (event) => {
  		publishBtn.closest("form").querySelector("#form_status").value = "published";
  	})
  }

  let restoreBtn = document.querySelector("#btn-restore");
  if (restoreBtn) {
  	restoreBtn.addEventListener("click", (event) => {
  		restoreBtn.closest("form").querySelector("#form_status").value = "published";
  	})
  }

  // Copy to clipboard from closest text input
  let clipboardBtn = document.querySelectorAll(".copy-to-clipboard");
  clipboardBtn.forEach((btn) => {	
  	btn.addEventListener("click", (event) => {
			let textInput = btn.closest(".share-link").querySelector(".form-control")
			if (textInput) {
				navigator.clipboard.writeText(textInput.value);
				btn.innerHTML = "Copied!"
			}
			event.preventDefault();
			event.stopPropagation()
		})
	})


  // Toggle inputs on checkbox checked (mainly for Form settings)
  let formSettingsCheckboxes = document.querySelectorAll("input.check-input-enable");
  if (formSettingsCheckboxes.length > 0) {
  	formSettingsCheckboxes.forEach((checkBox) => {
  		let siblingInputs = checkBox.closest("li").querySelectorAll("input:not([type='hidden'])")
  		checkBox.addEventListener("change", (event) => {
	  		if (checkBox.checked == true) {
	  			siblingInputs.forEach((inputDis) => {
	  				inputDis.disabled = false;
	  			})
	  		} else {
	  			siblingInputs.forEach((inputDis) => {
	  				inputDis.disabled = inputDis.type != "checkbox";
	  				inputDis.value = ""
	  			})
	  		}
	  	})
  	})
  }

})




