import Rails from '@rails/ujs';
import Sortable from 'sortablejs';
import * as bootstrap from "bootstrap"
import Dropzone from "dropzone"
import "dropzone/dist/dropzone.css";
import * as styleInit from "js/style_inits"


// Init Edit btns on the question card, so form edit is init when editor is opened
export function editBtnInit(btn, qId) {
	btn.addEventListener("ajax:success", (event) => {
		let questionEdit = document.querySelector("#form-question-"+qId);
		initQuestionForm(questionEdit);
		initSortableOptions(questionEdit);
		closeOpenQuestionCards(qId)
	})
} 

// Init Dup btns on the question card
export function dupBtnInit(btn, qId) {
	btn.addEventListener("ajax:success", (event) => {
		let questionEdit = document.querySelector("#form-question-"+qId).nextElementSibling;
		let editBtn = questionEdit.querySelector(".btn-edit-question");
		updateQuestionPositions();
		editBtnInit(editBtn, questionEdit.dataset.questionId);
	})
} 

// Init Delete btns on the question card
export function deleteBtnInit(btn, qId) {
	btn.addEventListener("ajax:success", (event) => {
		updateQuestionPositions();
	})
} 

export function addQuestionBtnInit(btn) {
	if (btn) { 
		btn.addEventListener("ajax:success", (event) => {
			initNewQuestionForm(btn);
		})
	}
}

export function initNewQuestionForm(addBtn) {
	// Add eventListeners to the newly created question form 
	var questionForm = null

	let formEl = addBtn.closest(".question-response-card")

	if (formEl) {
		// When a button is at the top of an existing question card
		questionForm = document.getElementById("form-question-" + formEl.dataset.questionId).previousElementSibling;
	} else if (addBtn.classList.contains("new-ending-btn")) {
		// When a button is at the bottom of endings
		questionForm = document.querySelector(".question-endings .col-12").lastElementChild;
	}	else {	
		// When a button is at the bottom of questions
		let questionEls = document.querySelectorAll(".questions .form-question-card")
		questionForm = questionEls[questionEls.length - 1] // skips the last element (add new button)
	}

	initQuestionForm(questionForm);
	closeOpenQuestionCards(questionForm.dataset.questionId);
	updateQuestionPositions();
}

export function initQuestionForm(formEl) {
	let questionInput = formEl.querySelector(".question-input");
	let questionForm = formEl.querySelector("form");
	let questionId = formEl.dataset.questionId;

	// Set Done button to init Edit button once pressed
	let doneBtn = formEl.querySelector(".btn-done-question")
	if (doneBtn) {
		doneBtn.addEventListener("ajax:success", (event) => {
			let editBtns = document.querySelectorAll("#form-question-"+questionId+" .btn-edit-question");
			editBtns.forEach((btn) => {
				editBtnInit(btn, questionId);	
			})

			let addQuestionBtn = document.querySelector("#form-question-"+questionId+" .new-question-btn");
			addQuestionBtnInit(addQuestionBtn);
		})
	}

	addQuestionBtnInit(formEl.querySelector(".new-question-btn"))

	// Init js functions when Choice input is selected
	let questionTypeSelect = formEl.querySelector(".add-choice")
	if (questionTypeSelect) {
		// Init sortable select options
		questionTypeSelect.addEventListener("ajax:success", (event) => {
			initSortableOptions(formEl);
		});
	}

	deleteBtnInit(formEl.querySelector(".delete-question"), questionId)
	dupBtnInit(formEl.querySelector(".btn-dup-question"), questionId)


	// Submit form on blur of every inline field
	formEl.addEventListener("focusout", function(event) {
		let el = event.target

		if (el.classList.contains("form-control-inline") || el.classList.contains("form-control-regular")) {
			Rails.fire(questionForm, "submit");
		}
	})

	formEl.addEventListener("click", (event) => {
		let el = event.target;

		if (el.classList.contains("btn-add-question-img")) {
			styleInit.updateImgModalIds(el, formEl)
		}

		if (el.classList.contains("bi-image") || el.hasAttribute("src")) {
			styleInit.updateImgModalIds(el.closest(".btn-add-question-img"), formEl)
		}
	})

	formEl.addEventListener("change", function(event) {
		let el = event.target

		if (el.classList.contains("form-check-input")) {
			// Switch text_field for text_area (and vice versa) when multiline checkbox has changed.
			if (el.classList.contains("multiline-checkbox")) {
				let invisible = formEl.querySelector(".question-input .invisible");
				let visible = formEl.querySelector(".question-input .visible");
				invisible.classList.replace("invisible", "visible");
				visible.classList.replace("visible", "invisible");
			}

			// Show/hide time input for the "date" question
			if (el.classList.contains("time-checkbox")) {
				let timeInput = formEl.querySelector(".time-input");
				timeInput.classList.toggle("invisible");
			}

			// Hide/show drag&drop handles for the "choice" question
			if (el.classList.contains("random-checkbox")) {
				let optionHandles = formEl.querySelectorAll(".option-handle");
				optionHandles.forEach((handle) => {
					handle.classList.toggle("invisible")
				})
			}

			// Show/hide branching options (select inputs)
			if (el.classList.contains("branching-checkbox")) {
				let branchingSelectors = formEl.querySelectorAll(".branch-label");
				branchingSelectors.forEach((select) => {
					select.classList.toggle("invisible")
				})
				let scaleBranchingSection = formEl.querySelector(".branching");
				if (scaleBranchingSection) {
					scaleBranchingSection.classList.toggle("invisible");
				}
			}

			Rails.fire(questionForm, "submit");
		}

		if (el.classList.contains("form-select-inline")) {

			// Change the scale size
			if (el.classList.contains("select-scale-size")) {
				let scaleExampleElements = formEl.querySelectorAll(".scale-example .col-1");
				let scaleBranchingOptions = formEl.querySelectorAll("li.option-item");
				let newScale = {};
				newScale[el.dataset.scale] = el.value;

				scaleExampleElements.forEach((scaleEl) => {
					hideOrShowScaleElement(scaleEl, newScale)
				})
				scaleBranchingOptions.forEach((scaleEl) => {
					hideOrShowScaleElement(scaleEl, newScale)
				})
			}

			// Change the scale icons
			if (el.classList.contains("select-scale-icons")) {
				let scaleExampleElements = formEl.querySelectorAll(".scale-example");
				let scaleIcon = el.value;
				scaleExampleElements.forEach((scaleEl) => {
					let iconEl = scaleEl.querySelector("h2");
					let iconNo = scaleEl.querySelector("h4");
					if (scaleIcon === "") {
						iconNo.classList.remove("invisible");
						iconEl.classList.add("invisible");
					} else {						
						iconEl.className = "";
						iconEl.classList.add("bi", ("bi-"+scaleIcon))
						iconEl.classList.remove("invisible");
						iconNo.classList.add("invisible");
					}
				})
			}
			Rails.fire(questionForm, "submit");
		}
	})

	questionInput.addEventListener("click", function(event) {
		let el = event.target

		// Add select options
		if (el.classList.contains("add-option-btn")) {
			addChoiceOption(el, formEl);
		}

		if (el.classList.contains("edit-placeholder")) {
			event.preventDefault()
			el.closest(".row").querySelector(".visible .form-control").disabled = false
		}

		// Remove select options
		if (el.classList.contains("delete-item")) {
			let optionItem = el.closest("li.option-item");
			let optionLabelInput = optionItem.querySelector(".option-label-input");
			if (optionLabelInput) {
				optionLabelInput.value = "";
				Rails.fire(questionForm, "submit");
				optionItem.outerHTML = "";
				if (optionItem.dataset.id == "other") {
					formEl.querySelector(".add-other-option-btn").classList.remove("invisible");
				}
				event.preventDefault();
			}
		}
	})

	questionInput.addEventListener('keypress', (event) => {
  	let el = event.target
    if (event.key === 'Enter' && el.classList.contains("option-label-input")) {
    	let addOptionBtn = questionInput.querySelector(".add-option-btn");
    	addChoiceOption(el, formEl);
    }
  })

	questionInput.addEventListener('focusin', (event) => {
  	let el = event.target
    if (el.classList.contains("option-label-input")) {
    	el.select();
    }
  })
}

function hideOrShowScaleElement(scaleEl, newScale) {
	let scaleMin = newScale["min"]
	let scaleMax = newScale["max"]
	let scaleElNo = parseInt(scaleEl.dataset.number)

	if ((scaleMin && scaleElNo < scaleMin) || (scaleMax && scaleElNo > scaleMax)) {
		scaleEl.classList.add("invisible");
	} else if ((scaleMin && scaleElNo < 4) || (scaleMax && scaleElNo > 3) )  {
		scaleEl.classList.remove("invisible");
	}
}

function addChoiceOption(el, formEl) {
	let questionForm = formEl.querySelector("form");
	let blankOption = questionForm.querySelector(".blank-select-option li");

	let choiceListClass = "ul.choice-list"
	if (el.dataset.id === "other") {choiceListClass = "ul.choice-list-other"}

	let clonedBlankOption = blankOption.cloneNode(true);	
	
	let lastChoice = questionForm.querySelector(choiceListClass + " li.option-item:last-child");

	// Append new option below the focused input
	if (el.nodeName == "INPUT") {
		lastChoice = el.closest("li");
	}

	lastChoice.insertAdjacentElement("afterend", clonedBlankOption);

	// Generate unique ID or set as "other" option
	if (el.dataset.id === undefined) {
		clonedBlankOption.dataset.id = uuidv4();			
		clonedBlankOption.setAttribute("id", "option-"+clonedBlankOption.dataset.id)
		clonedBlankOption.querySelector(".option-label-input").focus()
		clonedBlankOption.querySelector(".hidden-option-id").value = clonedBlankOption.dataset.id;
	} else {
		clonedBlankOption.dataset.id = el.dataset.id	// dataset.id = "Other"	
		clonedBlankOption.setAttribute("id", "option-other")
		clonedBlankOption.querySelector(".option-label-input").value = "Other"
		clonedBlankOption.querySelector(".option-label-input").readOnly = true	
		clonedBlankOption.querySelector(".option-handle").classList.add("invisible")
		formEl.querySelector(".add-other-option-btn").classList.add("invisible");
		clonedBlankOption.querySelector(".hidden-option-id").value = clonedBlankOption.dataset.id;
		Rails.fire(questionForm, "submit");
	}

	event.preventDefault();
}

export function initSortableQuestions() {
	let draggableUI = document.querySelector(".questions .card-body");
  if (draggableUI) {
    var questionSorter = new Sortable(draggableUI, {
        group: "questions", 
        animation: 300,
        handle: ".question-handle",
        dataIdAttr: "data-question-id",

        onEnd: (event) => {
        	updateQuestionPositions();
        }
    });
  };
}

function initSortableOptions(formEl) {
	let draggableUI = formEl.querySelector(".question-input .choice-list");
  if (draggableUI) {
    var optionsSorter = new Sortable(draggableUI, {
        group: "options", 
        animation: 300,
        handle: ".option-handle",
        dataIdAttr: "data-id",

        onEnd: (event) => {
        	let formId = document.querySelector(".form-tab").dataset.formid;
        	let questionId = formEl.dataset.questionId;
        	let csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
					postData('/forms/'+formId+'/questions/'+questionId + '/sort_options', csrfToken , { option_ids: optionsSorter.toArray() });
        }
    });
  };
}

export function updateQuestionPositions() {
	let formId = document.querySelector(".form-tab").dataset.formid;
	let csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
	let questionNodes = document.querySelectorAll(".questions .sortable-question");
	let questionIds = Array.from(questionNodes).map((q) => { return q.dataset["questionId"] })

	postData('/forms/'+formId+'/sort_questions', csrfToken , { question_ids: questionIds });

	questionNodes.forEach((q, i) => {
		let qNo = q.querySelector(".question-title em")
		if (qNo) {
			q.querySelector(".question-title em").innerHTML = (i+1).toString();
		}
	})	
}

function closeOpenQuestionCards(currentQuestionId) {
	// Close other opened question forms, except currentQuestionId
	let formId = document.querySelector(".form-tab").dataset.formid;
	let csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
	let openQuestions = document.querySelectorAll(".question-response-card .card.active");
	openQuestions.forEach((openQ) => {
		let questionForm = openQ.closest(".question-response-card");
		let openQuestionId = questionForm.dataset.questionId
		if (openQuestionId != currentQuestionId) {
			Rails.ajax({
				url: '/forms/'+formId+'/questions/'+openQuestionId+'?close_edit=true',
				type: "PUT",
				dataType: "script",
				success: function(data) {
					// Init edit buttons after successfully closed form
					let editBtns = document.querySelectorAll("#form-question-"+openQuestionId+" .btn-edit-question");
					editBtns.forEach((btn) => {
						editBtnInit(btn, openQuestionId);	
					})
				}
			})
		}
	})
}

async function postData(url = '', token, data = {}, method="POST") {
  // Default options are marked with *
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json', // 'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRF-TOKEN': token     
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}


export function getOrSetFormId() {
	// Get or set form ID from or to localStorage
	// Used to link formId for unregistered users, so they get ownership of form after they register

	let isForms = window.location.pathname.split("/")[1] == "forms"
	let formId = window.location.pathname.split("/")[2];
	let isEditor = window.location.pathname.split("/")[3] == "edit";
	let formIdFromStorage = localStorage.getItem("formId");
	let formIdInput = document.querySelector("#user_initial_form_id");

	// If formID exists, overwrite the localstorage, unless the same ID already exits
	if (isForms && isEditor && formId != formIdFromStorage) {
		localStorage.setItem("formId", formId);
	} else if (formIdInput && formIdFromStorage) {
		formIdInput.value = formIdFromStorage;
	}
}


function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}





